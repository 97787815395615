<template>
  <!-- Orders History Timeline Card -->
  <a-card :bordered="false">
    <template #title>
      <h6>การตั้งค่า</h6>
      <!-- <p>this month <span class="text-success">+20%</span></p> -->
    </template>
    <a-row :gutter="24" class="mt-10">
      <a-col :span="12" :lg="5" :xl="5">
        <div class="card-client m-0">
          <p class="name-client">รูปแบบการเล่น</p>
          <div class="social-media">
            <span> {{ botSetting.playerBetType }} </span>
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="5" :xl="5">
        <div class="card-client m-0">
          <p class="name-client">การเดินเงิน</p>
          <div class="social-media">
            <span> {{ botSetting.betMoneyType }} </span>
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="4" :xl="4">
        <div class="card-client m-0">
          <p class="name-client">ชิพเริ่มต้น</p>
          <div class="social-media">{{ botSetting.betAmount }}</div>
        </div>
      </a-col>
      <a-col :span="12" :lg="5" :xl="5">
        <div class="card-client m-0">
          <p class="name-client">กำไรเป้าหมาย</p>
          <div class="social-media">
            {{ botSetting.profitTarget.amount }}({{
              botSetting.profitTarget.percent
            }}%)
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="5" :xl="5">
        <div class="card-client m-0">
          <p class="name-client">กำหนดขาดทุนไม่เกิน</p>
          <div class="social-media">
            {{ botSetting.lossLimit.amount }}({{
              botSetting.lossLimit.percent
            }}%)
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- <a-timeline pending="Recording..." :reverse="timelineReverse">
      <a-timeline-item color="green">
        Game
        <p>{{ botSetting.gameType }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        รูปแบบการเล่น
        <p>{{ botSetting.playerBetType }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        การเดินเงิน
        <p>{{ botSetting.betMoneyType }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        ชิพเริ่มต้น
        <p>{{ botSetting.betAmount }}</p>
      </a-timeline-item>
      <a-timeline-item color="green">
        กำไรเป้าหมาย
        <p>
          {{ botSetting.profitTarget.amount }}({{
            botSetting.profitTarget.percent
          }}%)
        </p>
      </a-timeline-item>
      <a-timeline-item color="green">
        กำหนดขาดทุนไม่เกิน
        <p>
          {{ botSetting.lossLimit.amount }}({{ botSetting.lossLimit.percent }}%)
        </p>
      </a-timeline-item>
    </a-timeline> -->
  </a-card>
  <!-- / Orders History Timeline Card -->
</template>

<script>
export default {
  props: {
    botSetting: { type: Object, default: {} },
  },
  data() {
    return {
      // Whether or not the timeline in "Orders History" card is reversed.
      timelineReverse: false,
    };
  },
};
</script>
