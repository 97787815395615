var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"widget-1",attrs:{"bordered":false}},[_c('h6',[_vm._v("การเล่นปัจจุบัน")]),(Object.keys(_vm.data).length === 0)?_c('pulse-loader',{attrs:{"color":'#1f1f1f',"size":'14px'}}):_c('div',[_c('div',{staticClass:"board-half"},[_c('div',{staticClass:"pr-25 pl-25",staticStyle:{"text-align":"center"}},[_c('span',{class:[
            _vm.data.action != 'RESULTED' ? 'blink_me' : '',
            'card-content' ]},[_c('p',[_vm._v(" "+_vm._s(_vm.data.action != "RESULTED" ? ("กำลังเล่น โต๊ะที่ " + (_vm.data.bet.tableId) + " ตาที่ " + (_vm.data.bet.gameRound) + " ลงฝั่ง") : ("จบการเล่น โต๊ะที่ " + (_vm.data.bet.tableId) + " ตาที่ " + (_vm.data.bet.gameRound)))+" ")])]),_c('h4',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.data.bet.playerType))])])]),_c('div',{staticClass:"ant-timeline-item-content m-0 mt-10"},[_vm._v(" ผลการเล่น "),(_vm.data.action === 'RESULTED')?_c('div',{class:[
          'box  text-white ',
          _vm.data.bet.profit === 0
            ? 'bg-score-tie'
            : _vm.data.bet.profit < 0
            ? 'bg-score-lose'
            : 'bg-score-win' ]},[_c('h4',{staticClass:"m-10"},[_vm._v(" "+_vm._s(_vm.data.bet.profit === 0 ? "เสมอ" : _vm.data.bet.profit > 0 ? "ชนะ" : "แพ้")+" ")])]):_c('pulse-loader',{attrs:{"color":'#1f1f1f',"size":'14px'}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }