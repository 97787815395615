<template>
  <a-card :bordered="false" class="widget-1">
    <h6>การเล่นปัจจุบัน</h6>
    <pulse-loader
      :color="'#1f1f1f'"
      :size="'14px'"
      v-if="Object.keys(data).length === 0"
    />
    <div v-else>
      <!-- {{ data.action }} -->
      <div class="board-half">
        <div style="text-align: center" class="pr-25 pl-25">
          <span
            :class="[
              data.action != 'RESULTED' ? 'blink_me' : '',
              'card-content',
            ]"
            ><p>
              {{
                data.action != "RESULTED"
                  ? `กำลังเล่น โต๊ะที่ ${data.bet.tableId} ตาที่ ${data.bet.gameRound} ลงฝั่ง`
                  : `จบการเล่น โต๊ะที่ ${data.bet.tableId} ตาที่ ${data.bet.gameRound}`
              }}
            </p>
          </span>
          <h4 class="m-0">{{ data.bet.playerType }}</h4>
        </div>
      </div>

      <div class="ant-timeline-item-content m-0 mt-10">
        ผลการเล่น
        <div
          :class="[
            'box  text-white ',
            data.bet.profit === 0
              ? 'bg-score-tie'
              : data.bet.profit < 0
              ? 'bg-score-lose'
              : 'bg-score-win',
          ]"
          v-if="data.action === 'RESULTED'"
        >
          <h4 class="m-10">
            {{
              data.bet.profit === 0
                ? "เสมอ"
                : data.bet.profit > 0
                ? "ชนะ"
                : "แพ้"
            }}
          </h4>
        </div>
        <pulse-loader :color="'#1f1f1f'" :size="'14px'" v-else />
      </div>
    </div>
  </a-card>

  <!-- <a-card :bordered="false">
    <div id="charts">
      <h6>การเล่นปัจจุบัน</h6>
      {{ data }}
      <pulse-loader
        :color="'#1f1f1f'"
        :size="'14px'"
        v-if="Object.keys(data).length === 0"
      />
    </div>
  </a-card> -->
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  name: "",
  props: {
    data: { type: Object },
  },
  mounted() {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
#charts {
  /* background-image: linear-gradient(to right, #1890ff, #1890ff, #1890ff); */
  border-radius: 8px;
}
</style>
