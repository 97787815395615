<template>
  <!-- <div> -->
  <a-card :bordered="false">
    <h6>กราฟรายได้</h6>
    <div id="charts">
      <apexchart
        ref="realtimeChart"
        type="area"
        :options="chartOptions"
        :series="series"
        :height="height"
      />
    </div>
  </a-card>
  <!-- </div> -->
</template>

<script>
export default {
  name: "",
  props: {
    height: {
      type: Number,
    },
  },
  data: function () {
    return {
      currentProfit: 0,
      series: [
        {
          name: "PROFIT",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#3358ff"],

        stroke: {
          dashArray: 4,
        },
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 3,
          dashArray: 0,
        },

        xaxis: {
          type: "category",
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#8C8C8C",
            },
          },
        },
        yaxis: {
          show: true,
          showAlways: true,

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#8C8C8C",
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#8C8C8C1a",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },

  methods: {
    generateDayWiseTimeSeries: function (baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = baseval;
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
      }

      return series;
    },

    getChart(data) {
      this.currentProfit = 0;

      this.chartOptions.xaxis.categories = [];
      this.chartOptions.xaxis.categories.push("");
      this.series[0].data = [];

      this.series[0].data.push({
        x: "start",
        y: this.currentProfit,
      });

      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].isWin !== undefined) {
          this.addChart(data[i]);
        }
      }
      this.updateSeriesLine();
    },

    addChart(val) {
      this.currentProfit += val.profit;
      let label =
        "" +
        "ฝั่งที่เล่น " +
        val.playerType +
        "<br>" +
        "กำไร " +
        val.profit +
        "<br>" +
        "กำไรสะสม " +
        this.currentProfit +
        "<br>เวลา " +
        new Date(val.createdAt).toLocaleString("th-TH", {
          timeZone: "Asia/Bangkok",
        });
      this.series[0].data.push({
        x: label,
        y: this.currentProfit,
      });
      this.chartOptions.xaxis.categories.push(label);
    },

    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },
  },
};
</script>

<style scoped>
#charts {
  /* background-image: linear-gradient(to right, #1890ff, #1890ff, #1890ff); */
  border-radius: 8px;
}
</style>
