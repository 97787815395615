<template>
  <!-- Projects Table Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
    v-if="!hideCard"
  >
    <template #title v-if="!hideTitle">
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6>ประวัติการเล่น</h6>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="data" :pagination="true">
      <!-- <a-space
        slot="members"
        slot-scope="members"
        :size="-12"
        class="avatar-chips"
      >
      </a-space>

      <template slot="company" slot-scope="company">
        <h6 class="m-0">
          <img :src="company.logo" width="25" class="mr-10" />
          {{ company.name }}
        </h6>
      </template>

      <template slot="completion" slot-scope="completion">
        <span>{{ completion.label ? completion.label : completion }}</span>
        <a-progress
          :percent="completion.value ? completion.value : completion"
          :show-info="false"
          size="small"
          :status="completion.status ? completion.status : 'normal'"
        />
      </template> -->

      <template slot="createdAt" slot-scope="createdAt">
        <span>{{
          new Date(createdAt).toLocaleString("th-TH", {
            timeZone: "Asia/Bangkok",
          })
        }}</span>
      </template>
    </a-table>
  </a-card>
  <!-- / Projects Table Card -->
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    gameType: {
      type: String,
    },
    hideTitle: {
      type: Boolean,
    },
    hideCard: {
      type: Boolean,
    },
  },
  mounted() {},
  data() {
    return {
      // Active button for the "Projects" table's card header radio button group.
      projectHeaderBtns: "all",
    };
  },
  methods: {},
};
</script>
